export function menuTrigger() {
  let body = document.querySelector("body");
  let trigger = document.querySelector(".js-menuTrigger");
  let menu = document.querySelector(".js-menu");
  let links = document.querySelectorAll(".js-menu_link");

  function judgeMenuActive(item, className = "is-active") {
    if(item.classList.contains(className)) {
      item.classList.remove(className);
    } else {
      item.classList.add(className);
    }
  }

  trigger.addEventListener("click", () => {
    judgeMenuActive(trigger);
    judgeMenuActive(menu);
    judgeMenuActive(body, "is-overflow-hidden");
  });

  links.forEach(function(item, index){
    item.addEventListener("click", () => {
      judgeMenuActive(trigger);
      judgeMenuActive(menu);
      judgeMenuActive(body, "is-overflow-hidden");
    });
  })
}